/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-02-11",
    versionChannel: "nightly",
    buildDate: "2023-02-11T00:18:56.442Z",
    commitHash: "a2cd3ee06edcb889e8adc70a221eabb80ad342e5",
};
